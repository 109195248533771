import { useTaskWorkspaceQuery } from "../../generated/graphql";

export function useTask(taskId: number) {
  const taskQueryResult = useTaskWorkspaceQuery({
    fetchPolicy: "no-cache",
    variables: {
      taskId,
    },
  });

  const task = taskQueryResult.data?.task ?? null;

  return {
    loading: taskQueryResult.loading,
    error: taskQueryResult.error,
    task,
  };
}
