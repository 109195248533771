export const PAGE_LIMIT = 20;

export const DATE_FORMAT = "yyyy-MM-dd";

export const DATE_MONTH_FORMAT = "MMM d, yyyy";

export const DATE_TIME_FORMAT = "MMM d, yyyy h:mm a";

export const MONTH_YEAR = "MMM yyyy";

export const DATE_PICKER_FORMAT = "MMM d, yyyy";

export const DATE_FULL_FORMAT = "yyyyMMdd";

export const DATE_TEXT_FORMAT = "MMM d";

export const GOOGLE_TOKEN = "google-token";

export const SYSTEM_USER_ID = 1;
