export const TASK_TYPES = {
  BLOG_CONTENT_REVIEW: "blog-content-review",
  BLOG_CONTENT: "blog-content",
  BUSINESS_LISTING: "business-listing",
  CHECKLIST: "checklist",
  CONTENT_CALENDAR_CUSTOMER_REVIEW: "content-calendar-customer-review",
  CONTENT_CALENDAR: "content-calendar",
  CONTENT_STRATEGY: "content-strategy",
  CONTENT_STRATEGY_QA_REVIEW: "content-strategy-qa-review",
  CONTENT_STRATEGY_CUSTOMER_REVIEW: "content-strategy-customer-review",
  CONTENT: "content",
  AI_EDITED_CONTENT: "ai-edited-content",
  CONTENT_REVIEW: "content-review",
  CUSTOMER_CONTENT_REVIEW: "customer-content-review",
  KEYWORD_RESEARCH_CUSTOMER_REVIEW: "keyword-research-customer-review",
  KEYWORD_RESEARCH: "keyword-research-v2",
  SITE_AUDIT: "site-audit",
  WAITING_FOR_EXTERNAL_CONTENT: "waiting-for-external-content",
  CONTENT_FULFILLMENT_DECISION: "content-fulfillment-decision",
  PUBLISH_POST: "publish-post",
  PUBLISH_WEB_COPY_POST: "publish-web-copy-post",
  GUEST_ARTICLE_OUTREACH: "guest-article-outreach",
  CONTENT_IDEATION: "content-ideation",
  REVIEW_TITLE_TAG_META_DESCRIPTION: "review-title-tag-meta-description",
  PUBLISH_TITLE_META_DESCRIPTION: "publish-title-meta-description",
  LINK_BUILDING: "link-building",
  GBP_UPDATE: "gbp-update",
  PUBLISH_GBP_UPDATE: "publish-gbp-update",
};

export const SUB_TASK_TYPES = {
  WEBSITE_SELECTION: "website-selection",
  PROPOSAL_REVIEW: "website-proposal-review",
  CONTENT_SEND: "publisher-content-send",
  PUBLISHER_CONTENT_REVIEW: "publisher-content-review",
  QA_CONTENT_UPDATE: "qa-content-update",
  VERIFY_PUBLISHED_LINK: "verify-published-link",
  PUBLISHER_PAY: "publisher-pay",
};
