export const PRODUCTS = {
  BLOG_POST_1000_1200_WORDS: "blog-post-1000-1200-words",
  BLOG_POST_400_800_WORDS: "blog-post-400-800-words",
  BLOG_POST_400_WORDS: "blog-post-400-words",
  BLOG_POST_800_1000_WORDS: "blog-post-800-1000-words",
  BLOG_POST_1200_1500_WORDS: "blog-post-1200-1500-words",
  BLOG_POST_INDUSTRY_INFLUENCER: "blog-post-industry-influencer",
  BLOG_POST_PREMIUM: "blog-post-premium",
  BLOG_POST_600_WORDS: "blog-post-600-words",
  BLOG_POST_700_WORDS: "blog-post-700-words",
  ONSITE_ARTICLE_500_WORDS: "onsite-article-500-words",
  ONSITE_ARTICLE_800_WORDS: "onsite-article-800-words",
  ONSITE_ARTICLE_1000_WORDS: "onsite-article-1000-words",
  CLIENT_CONTENT_REVIEW: "client-content-review",
  BUSINESS_DIRECTORY_AUDIT: "business-directory-audit",
  BUSINESS_LISTING: "business-listing",
  BUSINESS_LISTING_PREMIUM: "business-listing-premium",
  BUSINESS_LISTING_SUBSCRIPTION: "business-listing-subscription",
  BUSINESS_LISTING_REVIEW_SUBSCRIPTION: "business-listing-review-subscription",
  REVIEW_SUBSCRIPTION: "review-subscription",
  CONTENT_STRATEGY_V2: "content-strategy-v2",
  CUSTOM: "custom",
  ECOMMERCE_AUDIT: "ecommerce-audit",
  GUEST_ARTICLE_DA_10_500_WORDS: "guest-article-da-10-500-words",
  GUEST_ARTICLE_DA_10_20: "guest-article-da-10-20",
  GUEST_ARTICLE_DA_11_20_1000_WORDS: "guest-article-da-10-20-1000-words",
  GUEST_ARTICLE_DA_21_30: "guest-article-da-21-30",
  GUEST_ARTICLE_DA_21_30_1000_WORDS: "guest-article-da-21-30-1000-words",
  GUEST_ARTICLE_DA_31_PLUS: "guest-article-da-31-plus",
  GUEST_ARTICLE_DA_31_40_1500_WORDS: "guest-article-da-31-40-1500-words",
  GUEST_ARTICLE_DA_41_50_1000_WORDS: "guest-article-da-41-50-1000-words",
  GUEST_ARTICLE_DA_41_50_1500_WORDS: "guest-article-da-41-50-1500-words",
  GUEST_ARTICLE_DA_41_50_2000_WORDS: "guest-article-da-41-50-2000-words",
  GUEST_ARTICLE_DA_30_PLUS_100_PLUS: "guest-article-da-30-plus-100-plus",
  KEYWORD_RANK_TRACKING: "keyword-rank-tracking",
  KEYWORD_RESEARCH: "keyword-research",
  SITE_AUDIT: "site-audit",
  SITE_AUDIT_MONTHLY: "site-audit-monthly",
  FREE_SITE_AUDIT: "free-site-audit",
  WEB_COPY_ABOUT_PAGE: "web-copy-about-page",
  WEB_COPY_ECOMMERCE_PRODUCT_DESCRIPTION: "web-copy-ecommerce-product-description",
  WEB_COPY_FAQS_PAGE: "web-copy-faqs-page",
  WEB_COPY_GENERAL_PAGE: "web-copy-general-page",
  WEB_COPY_HOME_PAGE: "web-copy-home-page",
  WEB_COPY_LANDING_PAGE: "web-copy-landing-page",
  WEB_COPY_PRODUCTS_SERVICES_PAGE: "web-copy-products-services-page",
  WEB_COPY_LANDING_PAGE_800_3000_WORDS: "web-copy-landing-page-800-3000-words",
  WEB_COPY_LANDING_PAGE_800_WORDS: "web-copy-landing-page-800-words",
  WEB_COPY_LANDING_PAGE_1000_WORDS: "web-copy-landing-page-1000-words",
  WEB_COPY_OPTIMIZE_LANDING_PAGE: "web-copy-optimize-landing-page",
  WEB_COPY_REVIEW: "web-copy-review",
  WEB_COPY_REFRESH: "web-copy-refresh",
  WEBSITE_STRATEGY: "website-strategy",
  BRAND_MANAGER_MEETING: "brand-manager-meeting",
  ACCOUNT_MANAGEMENT_BASIC: "account-management-basic",
  ACCOUNT_MANAGEMENT_STANDARD: "account-management-standard",
  ACCOUNT_MANAGEMENT_PREMIUM: "account-management-premium",
  ACCOUNT_MANAGEMENT_250: "account-management-250",
  ACCOUNT_MANAGEMENT_400: "account-management-400",
  ACCOUNT_MANAGEMENT_500: "account-management-500",
  PUBLISH_POST: "publish-post",
  WEBSITE_HOSTING: "website-hosting",
  WEB_DESIGN_4_PAGES: "web-design-4-pages",
  WEB_DESIGN_5_PAGES: "web-design-5-pages",
  WEB_DESIGN_6_PAGES: "web-design-6-pages",
  BUSINESS_CITATION_SUBSCRIPTION: "business-citation-subscription",
  CONTENT_IDEATION: "content-ideation",
  TITLE_TAG_META_DESCRIPTION: "title-tag-meta-description",
  LINK_BUILDING_25: "link-building-25",
  LINK_BUILDING_50: "link-building-50",
  LINK_BUILDING_75: "link-building-75",
  LINK_BUILDING_100: "link-building-100",
  ACCOUNT_MANAGEMENT_150: "account-management-150",
  SEO_SPECIALIST_300: "seo-specialist-300",
  SEO_SPECIALIST_350: "seo-specialist-350",
  SEO_SPECIALIST_400: "seo-specialist-400",
  SEO_SPECIALIST_3425: "seo-specialist-3425",
  GBP_UPDATE: "gbp-update",
};

export const BLOG_POST_PRODUCTS = [
  PRODUCTS.BLOG_POST_400_WORDS,
  PRODUCTS.BLOG_POST_400_800_WORDS,
  PRODUCTS.BLOG_POST_800_1000_WORDS,
  PRODUCTS.BLOG_POST_1000_1200_WORDS,
  PRODUCTS.BLOG_POST_1200_1500_WORDS,
  PRODUCTS.BLOG_POST_INDUSTRY_INFLUENCER,
  PRODUCTS.BLOG_POST_PREMIUM,
  PRODUCTS.BLOG_POST_600_WORDS,
  PRODUCTS.BLOG_POST_700_WORDS,
  PRODUCTS.ONSITE_ARTICLE_500_WORDS,
  PRODUCTS.ONSITE_ARTICLE_800_WORDS,
  PRODUCTS.ONSITE_ARTICLE_1000_WORDS,
];

export const WEB_COPY_PRODUCTS = [
  PRODUCTS.WEB_COPY_ABOUT_PAGE,
  PRODUCTS.WEB_COPY_FAQS_PAGE,
  PRODUCTS.WEB_COPY_GENERAL_PAGE,
  PRODUCTS.WEB_COPY_HOME_PAGE,
  PRODUCTS.WEB_COPY_LANDING_PAGE,
  PRODUCTS.WEB_COPY_PRODUCTS_SERVICES_PAGE,
  PRODUCTS.WEB_COPY_LANDING_PAGE_800_3000_WORDS,
  PRODUCTS.WEB_COPY_LANDING_PAGE_800_WORDS,
  PRODUCTS.WEB_COPY_LANDING_PAGE_1000_WORDS,
  PRODUCTS.WEB_COPY_OPTIMIZE_LANDING_PAGE,
  PRODUCTS.WEB_COPY_REVIEW,
  PRODUCTS.WEB_COPY_REFRESH,
];

export const GUEST_ARTICLE_PRODUCTS = [
  PRODUCTS.GUEST_ARTICLE_DA_10_500_WORDS,
  PRODUCTS.GUEST_ARTICLE_DA_10_20,
  PRODUCTS.GUEST_ARTICLE_DA_11_20_1000_WORDS,
  PRODUCTS.GUEST_ARTICLE_DA_21_30,
  PRODUCTS.GUEST_ARTICLE_DA_21_30_1000_WORDS,
  PRODUCTS.GUEST_ARTICLE_DA_31_PLUS,
  PRODUCTS.GUEST_ARTICLE_DA_31_40_1500_WORDS,
  PRODUCTS.GUEST_ARTICLE_DA_41_50_1000_WORDS,
  PRODUCTS.GUEST_ARTICLE_DA_41_50_1500_WORDS,
  PRODUCTS.GUEST_ARTICLE_DA_41_50_2000_WORDS,
  PRODUCTS.GUEST_ARTICLE_DA_30_PLUS_100_PLUS,
];

export const CLIENT_REVIEW_PRODUCTS = [PRODUCTS.CLIENT_CONTENT_REVIEW];

export const SITE_AUDIT_PRODUCTS = [PRODUCTS.SITE_AUDIT, PRODUCTS.SITE_AUDIT_MONTHLY, PRODUCTS.FREE_SITE_AUDIT];

export const CONTENT_PRODUCTS = [
  ...BLOG_POST_PRODUCTS,
  ...WEB_COPY_PRODUCTS,
  ...GUEST_ARTICLE_PRODUCTS,
  ...CLIENT_REVIEW_PRODUCTS,
];

export const DELIVERED_CONTENT_PRODUCTS = [...BLOG_POST_PRODUCTS, ...WEB_COPY_PRODUCTS];

export const BUSINESS_LISTING_PRODUCTS = [PRODUCTS.BUSINESS_LISTING];

export const PREMIUM_BUSINESS_LISTING_PRODUCTS = [PRODUCTS.BUSINESS_LISTING_PREMIUM];

export const ACCOUNT_MANAGEMENT_PRODUCTS = [
  PRODUCTS.ACCOUNT_MANAGEMENT_BASIC,
  PRODUCTS.ACCOUNT_MANAGEMENT_STANDARD,
  PRODUCTS.ACCOUNT_MANAGEMENT_PREMIUM,
  PRODUCTS.ACCOUNT_MANAGEMENT_250,
  PRODUCTS.ACCOUNT_MANAGEMENT_400,
  PRODUCTS.ACCOUNT_MANAGEMENT_500,
  PRODUCTS.BUSINESS_CITATION_SUBSCRIPTION,
  PRODUCTS.ACCOUNT_MANAGEMENT_150,
  PRODUCTS.SEO_SPECIALIST_300,
  PRODUCTS.SEO_SPECIALIST_350,
  PRODUCTS.SEO_SPECIALIST_400,
  PRODUCTS.SEO_SPECIALIST_3425,
  PRODUCTS.REVIEW_SUBSCRIPTION,
];

export const LINK_BUILDING_PRODUCTS = [
  PRODUCTS.LINK_BUILDING_25,
  PRODUCTS.LINK_BUILDING_50,
  PRODUCTS.LINK_BUILDING_75,
  PRODUCTS.LINK_BUILDING_100,
];

export const GBP_UPDATE_PRODUCTS = [PRODUCTS.GBP_UPDATE];

export const PRODUCT_CATEGORY: { [index: string]: any } = {
  blog_post_all: {
    name: "Blog Posts Products",
    productIds: BLOG_POST_PRODUCTS,
  },
  web_copy_all: {
    name: "Web Copy Products",
    productIds: WEB_COPY_PRODUCTS,
  },
  guest_article_all: {
    name: "Guest Article Products",
    productIds: GUEST_ARTICLE_PRODUCTS,
  },
  business_listing_all: {
    name: "Business Listing Products",
    productIds: BUSINESS_LISTING_PRODUCTS,
  },
  premium_business_listing_all: {
    name: "Premium Business Listing Products",
    productIds: PREMIUM_BUSINESS_LISTING_PRODUCTS,
  },
  client_content_review_all: {
    name: "Client Content Review Products",
    productIds: CLIENT_REVIEW_PRODUCTS,
  },
};
