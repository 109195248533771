import { SecondaryNav, SecondaryNavLink } from "../../components/layout";

interface Props {
  customerId: number;
  customerName: string;
  isBillingVisible: boolean;
  activeSection?: string;
}

export function ClientDetailsSecondaryNav(props: Props) {
  const { customerId, customerName, activeSection, isBillingVisible } = props;
  const NAV_ITEMS = [
    {
      section: "overview",
      displayName: "Overview",
      visible: true,
    },
    {
      section: "campaigns",
      displayName: "Campaigns",
      visible: true,
    },
    {
      section: "roadmap",
      displayName: "Roadmap",
      visible: true,
    },
    {
      section: "messages",
      displayName: "Messages",
      visible: true,
    },
    {
      section: "emails",
      displayName: "Emails",
      visible: true,
    },
    {
      section: "keywords",
      displayName: "Keywords",
      visible: true,
    },
    {
      section: "users",
      displayName: "Users",
      visible: true,
    },
    {
      section: "locations",
      displayName: "Locations",
      visible: true,
    },
    {
      section: "invoices",
      displayName: "Invoices",
      visible: isBillingVisible,
    },
  ];
  return (
    <SecondaryNav title={customerName}>
      {NAV_ITEMS.map((item) => {
        if (!item.visible) {
          return null;
        }
        return (
          <SecondaryNavLink
            key={item.section}
            isActive={activeSection === item.section}
            to={`/clients/${customerId}/${item.section}`}
          >
            {item.displayName}
          </SecondaryNavLink>
        );
      })}
    </SecondaryNav>
  );
}
