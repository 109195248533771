import { useParams, Navigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

import { useTask } from "./use-task";
import { TaskWorkspaceWrapper } from "./task-workspace-wrapper";
import { Loader } from "../../components/loader";
import { TaskWorkspace } from "./task-workspace";
import { getDisplayMessageForError } from "../../util/error-helper";

export function TaskRoute() {
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const taskId = parseInt(id ?? "");
  const { loading, task, error } = useTask(taskId);

  if (loading) {
    return (
      <TaskWorkspaceWrapper>
        <Loader type="content" />
      </TaskWorkspaceWrapper>
    );
  }

  if (error) {
    toast({
      title: "Unable to Load Task",
      description: getDisplayMessageForError(error),
      status: "error",
    });
    return <Navigate to="/tasks" />;
  }

  if (!task) {
    toast({
      title: "Unable to Load Task",
      description: `Unable to load task (ID: ${taskId}). Please try again.`,
      status: "error",
    });
    return <Navigate to="/tasks" />;
  }

  if (task?.isSystemTaskInProgress) {
    toast({
      title: "Unable to Load Task",
      description: `Unable to load task (ID: ${taskId}). This task is in progress.`,
      status: "error",
    });
    return <Navigate to="/tasks" />;
  }

  return (
    <TaskWorkspaceWrapper>
      <TaskWorkspace task={task} />
    </TaskWorkspaceWrapper>
  );
}
