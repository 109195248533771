import * as yup from "yup";
import { useParams } from "react-router-dom";
import { FormikHelpers, useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import { useState } from "react";

import {
  useAdminUpdateLinkLocationMetricsMutation,
  useLinkLocationDetailsQuery,
  useSaveLinkLocationCredentialMutation,
  useUpdateLinkLocationDetailsMutation,
  useUpdatePublisherLinkLocationMutation,
} from "../../generated/graphql";
import { handleFormGraphQLError } from "../../util/error-helper";
import { FormHelpers } from "../../util/form-helpers";

interface FormValues {
  domain: string;
  domainAuthority: number;
  notes: string;
  minimumWordCount: number;
  spam?: number | null;
  authorityScore?: number | null;
  internalQuality?: number | null;
  monthlyTraffic?: number | null;
  cost: number;
  contactName: string;
  contactEmail: string;
  allowsOnlyOneLinkInContent: boolean;
  allowsCbdContent: boolean;
  allowsWeapons: boolean;
  allowsAIContent: boolean;
  statusId: string;
  typeId: string;
  publisher: string;
  categoryIds: number[];
  isRCMPublishing: boolean;
  adminUrl?: string;
  user?: string;
  password?: string;
  instructions?: string;
  createdAt: Date;
  lastMetricsUpdatedAt?: Date | null;
}

const requiredIfRCMPublishing = yup.string().when("isRCMPublishing", {
  is: true,
  then: yup.string().required(),
  otherwise: yup.string().notRequired(),
});

const validationSchema = yup.object().shape({
  domainAuthority: yup.number().label("Domain Authority").notRequired(),
  notes: yup.string().label("Notes").notRequired(),
  minimumWordCount: yup.number().label("Minimum Word Count").notRequired(),
  spam: yup.number().label("Spam Score").notRequired().nullable(true),
  cost: yup.number().label("Cost").notRequired(),
  contactName: yup.string().label("Contact Name").notRequired(),
  contactEmail: yup.string().label("Contact Email").notRequired().email(),
  allowsOnlyOneLinkInContent: yup.bool().label("Allows Only One Link In Content").notRequired(),
  allowsCbdContent: yup.bool().label("Allows Cbd Content").notRequired(),
  statusId: yup.string().label("Status Id").notRequired(),
  typeId: yup.string().label("Type Id").notRequired(),
  authorityScore: yup.number().label("Authority Score").notRequired().nullable(true),
  internalQuality: yup.number().label("Internal Quality").notRequired().nullable(true),
  monthlyTraffic: yup.number().label("Monthly Traffic").notRequired().nullable(true),
  isRCMPublishing: yup.boolean().label("Is RCM Publishing").notRequired().default(false).nullable(false),
  adminUrl: requiredIfRCMPublishing,
  user: requiredIfRCMPublishing,
  password: requiredIfRCMPublishing,
  instructions: yup.string().label("Instructions").notRequired().nullable(true),
});

const LINK_LOCATION_STATUS = {
  PENDING: {
    id: "pending",
    name: "Pending",
  },
  PENDING_NEW: {
    id: "pending-new",
    name: "Pending (New)",
  },
  ACTIVE: {
    id: "active",
    name: "Active",
  },
  INACTIVE: {
    id: "inactive",
    name: "Inactive",
  },
};

const LINK_LOCATION_TYPES = {
  PBN: {
    id: "pbn",
    name: "PBN",
  },
  OUTREACH: {
    id: "outreach",
    name: "Outreach",
  },
  LOCAL_BUSINESS: {
    id: "local-business",
    name: "Local Business",
  },
};

export function useAdminLinkLocation() {
  const { id } = useParams<"id">();
  const linkLocationId = parseInt(id ?? "");
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();

  const [updateLinkLocation] = useUpdateLinkLocationDetailsMutation();
  const [updatePublisherLinkLocation] = useUpdatePublisherLinkLocationMutation();
  const [saveLinkLocationCredential] = useSaveLinkLocationCredentialMutation();
  const [updateLinkLocationMetrics] = useAdminUpdateLinkLocationMetricsMutation();

  const queryResult = useLinkLocationDetailsQuery({
    fetchPolicy: "network-only",
    variables: {
      id: linkLocationId,
    },
  });

  const linkLocation = queryResult.data?.linkLocation ?? null;
  const isLinkedToPublisher = !!linkLocation?.publisherLinkLocation?.id;

  async function onSubmit(values: FormValues, helpers: FormikHelpers<FormValues>) {
    try {
      const response = await updateLinkLocation({
        variables: {
          input: {
            linkLocationId,
            domainAuthority: values.domainAuthority,
            notes: values.notes,
            minimumWordCount: values.minimumWordCount,
            spam: FormHelpers.processNullableInt(values.spam),
            cost: values.cost,
            contactName: values.contactName,
            contactEmail: values.contactEmail,
            allowsOnlyOneLinkInContent: values.allowsOnlyOneLinkInContent,
            allowsCbdContent: values.allowsCbdContent,
            allowsWeapons: values.allowsWeapons,
            allowsAIContent: values.allowsAIContent,
            statusId: values.statusId,
            typeId: values.typeId,
            authorityScore: FormHelpers.processNullableInt(values.authorityScore),
            internalQuality: FormHelpers.processNullableInt(values.internalQuality),
            monthlyTraffic: FormHelpers.processNullableInt(values.monthlyTraffic),
          },
          categoryInput: {
            linkLocationId,
            categoryIds: values.categoryIds,
          },
        },
      });

      if (linkLocation?.publisherLinkLocation?.id) {
        if (values.isRCMPublishing !== linkLocation?.publisherLinkLocation?.isRCMPublishing) {
          await updatePublisherLinkLocation({
            variables: {
              input: {
                publisherLinkLocationId: linkLocation.publisherLinkLocation.id,
                isRCMPublishing: values.isRCMPublishing,
              },
            },
          });
        }

        if (values.isRCMPublishing) {
          const linkLocationCredentialResponse = await saveLinkLocationCredential({
            variables: {
              input: {
                linkLocationId,
                adminUrl: values.adminUrl ?? "",
                userName: values.user ?? "",
                password: values.password ?? "",
                instructions: values.instructions,
              },
            },
          });
          if (!linkLocationCredentialResponse.data?.saveLinkLocationCredential.ok) {
            throw new Error(
              linkLocationCredentialResponse.data?.saveLinkLocationCredential.error?.message ??
                "An error occurred while trying to create the site. Please try again."
            );
          }
        }
      } else {
        if (values.isRCMPublishing && !!values.adminUrl) {
          toast({
            title: "Link Publisher and Link Location",
            status: "info",
          });
        }
      }

      if (response.data?.updateLinkLocation.ok && response.data?.updateLinkLocationCategories.ok) {
        toast({
          title: "Link Location updated successfully",
          description: "Your updates are saved!",
          status: "success",
        });
        await queryResult.refetch();
      } else {
        throw new Error(
          response.data?.updateLinkLocation.error?.message ??
            response.data?.updateLinkLocationCategories.error?.message ??
            "Something went wrong!"
        );
      }
    } catch (e: any) {
      handleFormGraphQLError(e, "Unable to Save Link Location", toast, helpers.setErrors);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      domain: linkLocation?.domain ?? "",
      domainAuthority: linkLocation?.domainAuthority ?? 0,
      notes: linkLocation?.notes ?? "",
      minimumWordCount: linkLocation?.minimumWordCount ?? 0,
      spam: linkLocation?.spam ?? 0,
      authorityScore: linkLocation?.authorityScore ?? undefined,
      internalQuality: linkLocation?.internalQuality ?? undefined,
      monthlyTraffic: linkLocation?.monthlyTraffic ?? undefined,
      cost: linkLocation?.cost ?? 0,
      contactName: linkLocation?.contactName ?? "",
      contactEmail: linkLocation?.contactEmail ?? "",
      allowsOnlyOneLinkInContent: linkLocation?.allowsOnlyOneLinkInContent ?? false,
      allowsCbdContent: linkLocation?.allowsCbdContent ?? false,
      allowsWeapons: linkLocation?.allowsWeapons ?? false,
      allowsAIContent: linkLocation?.allowsAIContent ?? false,
      statusId: linkLocation?.statusId ?? "",
      typeId: linkLocation?.typeId ?? "",
      publisher: linkLocation?.publisherLinkLocation?.publisher?.name ?? "",
      categoryIds:
        linkLocation?.linkLocationCategories.map((linkLocationCategory) => linkLocationCategory.categoryId) ?? [],
      isRCMPublishing: linkLocation?.publisherLinkLocation?.isRCMPublishing ?? false,
      createdAt: linkLocation?.createdAt ?? new Date(),
      lastMetricsUpdatedAt: linkLocation?.lastMetricsUpdatedAt,
    },
    enableReinitialize: true,
    onSubmit,
    validationSchema,
  });

  function onPasswordClick() {
    setShowPassword(!showPassword);
  }

  function onSave() {
    formik.handleSubmit();
  }

  function onCancel() {
    formik.resetForm();
  }

  function onCategoriesChange(categoryIds: number[]) {
    formik.setFieldValue("categoryIds", categoryIds);
  }

  async function onRefreshDomainMetrics() {
    const response = await updateLinkLocationMetrics({
      variables: {
        input: {
          linkLocationId,
        },
      },
    });

    if (response.data?.updateLinkLocationMetrics.ok) {
      toast({
        title: "Link Location Metrics updated successfully",
        description: "Your updates are saved!",
        status: "success",
      });
      await queryResult.refetch();
    } else {
      toast({
        title: "Link Location Metrics update failed",
        description: response.data?.updateLinkLocationMetrics.error?.message ?? "Something went wrong!",
        status: "error",
      });
    }
  }

  return {
    loading: queryResult.loading,
    formik,
    LINK_LOCATION_STATUS,
    LINK_LOCATION_TYPES,
    showPassword,
    onCategoriesChange,
    onPasswordClick,
    onSave,
    onCancel,
    isLinkedToPublisher,
    onRefreshDomainMetrics,
  };
}
