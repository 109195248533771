import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";

import { CategoryMultiSelector } from "../../common/category-selector";
import AppIcon from "../../components/app-icon";
import AppLink from "../../components/app-link";
import { CancelButton, PrimaryButton, PrimaryLinkButton, SecondaryButton } from "../../components/buttons";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { Loader } from "../../components/loader";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { useAdminLinkLocation } from "./use-admin-link-location";
import { DATE_MONTH_FORMAT } from "../../constants/constants";

export function AdminLinkLocationDetails() {
  const {
    formik,
    loading,
    onSave,
    onCancel,
    LINK_LOCATION_TYPES,
    LINK_LOCATION_STATUS,
    onCategoriesChange,
    onPasswordClick,
    showPassword,
    isLinkedToPublisher,
    onRefreshDomainMetrics,
  } = useAdminLinkLocation();

  return (
    <LayoutBase pageTitle="Link Location Details">
      <MainNav />
      <Container centerHorizontally>
        <Stack spacing={2} mt={4}>
          <AppLink to="/outreach/link-locations">
            <Text>
              <AppIcon icon={faChevronLeft} wideRightMargin />
              Back to Link Locations
            </Text>
          </AppLink>
          <PageHeading>
            <Text>Link Location Details</Text>
          </PageHeading>
        </Stack>
        <SimpleGrid columns={{ base: 1, xl: 2 }} gridGap={4}>
          <PanelGroup>
            <PanelTop showBottomBorder>
              <Heading size="md">Overview</Heading>
            </PanelTop>
            <PanelBottom>
              {loading && <Loader type="content" />}
              {!loading && (
                <Stack spacing={4}>
                  <FormControl isDisabled>
                    <FormLabel>Domain</FormLabel>
                    <Input variant="outline" id="domain" value={formik.values.domain} />
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.domainAuthority}>
                    <FormLabel>Domain Authority</FormLabel>
                    <Input
                      variant="outline"
                      id="domainAuthority"
                      value={formik.values.domainAuthority}
                      onChange={formik.handleChange}
                      type="number"
                    />
                    <FormErrorMessage>{formik.errors.domainAuthority}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.statusId}>
                    <FormLabel>Status</FormLabel>
                    <Select
                      id="statusId"
                      value={formik.values.statusId}
                      onChange={formik.handleChange}
                      placeholder="Not provided"
                      variant="outline"
                    >
                      {Object.values(LINK_LOCATION_STATUS).map((option) => (
                        <option value={option.id} key={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{formik.errors.statusId}</FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Created At</FormLabel>
                    <Input
                      variant="outline"
                      id="createdAt"
                      value={DateTime.fromJSDate(formik.values.createdAt).toFormat(DATE_MONTH_FORMAT) ?? ""}
                    />
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.typeId}>
                    <FormLabel>Type</FormLabel>
                    <Select
                      id="typeId"
                      value={formik.values.typeId}
                      onChange={formik.handleChange}
                      placeholder="Not provided"
                      variant="outline"
                    >
                      {Object.values(LINK_LOCATION_TYPES).map((option) => (
                        <option value={option.id} key={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{formik.errors.typeId}</FormErrorMessage>
                  </FormControl>
                  <FormControl isDisabled>
                    <FormLabel>Publisher</FormLabel>
                    <Input variant="outline" id="publisher" value={formik.values.publisher} />
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.notes}>
                    <FormLabel>Notes</FormLabel>
                    <Textarea
                      variant="outline"
                      id="notes"
                      value={formik.values.notes}
                      onChange={formik.handleChange}
                      resize="none"
                    />
                    <FormErrorMessage>{formik.errors.notes}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.minimumWordCount}>
                    <FormLabel>Minimum Word Count</FormLabel>
                    <Input
                      variant="outline"
                      id="minimumWordCount"
                      value={formik.values.minimumWordCount}
                      onChange={formik.handleChange}
                      type="number"
                    />
                    <FormErrorMessage>{formik.errors.minimumWordCount}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.touched.spam && !!formik.errors.spam}>
                    <FormLabel>Spam</FormLabel>
                    <Input
                      variant="outline"
                      value={formik.values.spam ?? undefined}
                      name="spam"
                      type="number"
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>{formik.errors.spam}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.touched.authorityScore && !!formik.errors.authorityScore}>
                    <FormLabel>Authority Score</FormLabel>
                    <Input
                      variant="outline"
                      value={formik.values.authorityScore ?? undefined}
                      name="authorityScore"
                      type="number"
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>{formik.errors.authorityScore}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.touched.internalQuality && !!formik.errors.internalQuality}>
                    <FormLabel>Internal Quality</FormLabel>
                    <Input
                      variant="outline"
                      value={formik.values.internalQuality ?? undefined}
                      name="internalQuality"
                      type="number"
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>{formik.errors.internalQuality}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.touched.monthlyTraffic && !!formik.errors.monthlyTraffic}>
                    <FormLabel>Monthly Traffic</FormLabel>
                    <Input
                      variant="outline"
                      value={formik.values.monthlyTraffic ?? undefined}
                      name="monthlyTraffic"
                      type="number"
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>{formik.errors.monthlyTraffic}</FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Last Metrics Updated At</FormLabel>
                    <Input
                      variant="outline"
                      id="createdAt"
                      value={
                        formik.values.lastMetricsUpdatedAt
                          ? DateTime.fromJSDate(formik.values.lastMetricsUpdatedAt).toUTC().toFormat(DATE_MONTH_FORMAT)
                          : "-"
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <SecondaryButton onClick={onRefreshDomainMetrics}>Refresh Domain Metrics</SecondaryButton>
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.cost}>
                    <FormLabel>Cost</FormLabel>
                    <InputGroup>
                      <InputLeftElement color="gray.300" fontSize="1.2em">
                        $
                      </InputLeftElement>
                      <Input
                        placeholder="Enter cost"
                        variant="outline"
                        id="cost"
                        value={formik.values.cost}
                        type="number"
                        onChange={formik.handleChange}
                      />
                    </InputGroup>
                    <FormErrorMessage>{formik.errors.cost}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.contactName}>
                    <FormLabel>Contact Name</FormLabel>
                    <Input
                      variant="outline"
                      id="contactName"
                      value={formik.values.contactName}
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>{formik.errors.contactName}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.contactEmail}>
                    <FormLabel>Contact Email</FormLabel>
                    <Input
                      variant="outline"
                      id="contactEmail"
                      value={formik.values.contactEmail}
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>{formik.errors.contactEmail}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.allowsCbdContent}>
                    <FormLabel>Allows CBD Content</FormLabel>
                    <Switch
                      id="allowsCbdContent"
                      isChecked={formik.values.allowsCbdContent}
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>{formik.errors.allowsCbdContent}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.allowsOnlyOneLinkInContent}>
                    <FormLabel>Allows Only One Link In Content</FormLabel>
                    <Switch
                      id="allowsOnlyOneLinkInContent"
                      isChecked={formik.values.allowsOnlyOneLinkInContent}
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>{formik.errors.allowsOnlyOneLinkInContent}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.allowsWeapons}>
                    <FormLabel>Allows Weapons</FormLabel>
                    <Switch id="allowsWeapons" isChecked={formik.values.allowsWeapons} onChange={formik.handleChange} />
                    <FormErrorMessage>{formik.errors.allowsWeapons}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.allowsAIContent}>
                    <FormLabel>Allows AI Content</FormLabel>
                    <Switch
                      id="allowsAIContent"
                      isChecked={formik.values.allowsAIContent}
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>{formik.errors.allowsAIContent}</FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Categories</FormLabel>
                    <CategoryMultiSelector
                      selectedCategoryIds={formik.values.categoryIds}
                      onCategorySelected={onCategoriesChange}
                    />
                  </FormControl>
                  {isLinkedToPublisher && (
                    <FormControl isRequired isInvalid={!!formik.errors.isRCMPublishing}>
                      <FormLabel>Is RedCanyonMedia publishing onsite blogs?</FormLabel>
                      <RadioGroup
                        id="isRCMPublishing"
                        value={formik.values.isRCMPublishing ? "yes" : "no"}
                        onChange={(value) => {
                          formik.setFieldValue("isRCMPublishing", value === "yes");
                        }}
                      >
                        <Stack spacing={5} isInline>
                          <Radio value="yes">Yes</Radio>
                          <Radio value="no">No</Radio>
                        </Stack>
                      </RadioGroup>
                      <FormErrorMessage>{formik.errors.isRCMPublishing}</FormErrorMessage>
                    </FormControl>
                  )}
                  {formik.values.isRCMPublishing && (
                    <>
                      <FormControl isRequired isInvalid={!!formik.errors.adminUrl}>
                        <FormLabel>Admin URL</FormLabel>
                        <Input
                          id="adminUrl"
                          value={formik.values.adminUrl}
                          onChange={formik.handleChange}
                          variant="outline"
                        />
                        <FormErrorMessage>{formik.errors.adminUrl}</FormErrorMessage>
                      </FormControl>
                      <FormControl isRequired isInvalid={!!formik.errors.user}>
                        <FormLabel>User Name</FormLabel>
                        <Input id="user" value={formik.values.user} onChange={formik.handleChange} variant="outline" />
                        <FormErrorMessage>{formik.errors.user}</FormErrorMessage>
                      </FormControl>
                      <FormControl isRequired isInvalid={!!formik.errors.password}>
                        <FormLabel>Password</FormLabel>
                        <InputGroup size="md">
                          <Input
                            id="password"
                            type={showPassword ? "text" : "password"}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            variant="outline"
                          />
                          <InputRightElement width="4.5rem">
                            <PrimaryLinkButton h="1.75rem" size="sm" onClick={onPasswordClick}>
                              {showPassword ? "Hide" : "Show"}
                            </PrimaryLinkButton>
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!formik.errors.instructions}>
                        <FormLabel>Instructions</FormLabel>
                        <Textarea
                          value={formik.values.instructions}
                          id="instructions"
                          name="instructions"
                          onChange={formik.handleChange}
                          resize="none"
                        />
                        <FormErrorMessage>{formik.errors.instructions}</FormErrorMessage>
                      </FormControl>
                    </>
                  )}
                  {formik.dirty && (
                    <Box>
                      <PrimaryButton onClick={onSave} marginRight="8px">
                        Save
                      </PrimaryButton>
                      <CancelButton onClick={onCancel}>Cancel</CancelButton>
                    </Box>
                  )}
                </Stack>
              )}
            </PanelBottom>
          </PanelGroup>
        </SimpleGrid>
      </Container>
    </LayoutBase>
  );
}
